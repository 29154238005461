import { onMounted, reactive, watch, nextTick, ref } from '@vue/composition-api'
import { newStateModel } from '@/types/pages/index'
import { newsItemType } from '@/common/newsData'
import { parseTime } from '@/utils/date'
import router from '@/router'
import $http from '@/api'
import _ from 'lodash'

export default function useNews() {
  const news = ref<newsItemType[]>([])
  const media = ref<newsItemType[]>([])

  const init = async () => {
    try {
      const { data } = await $http.getHomeNews()
      news.value = data.newsCompany
      media.value = data.newsMedia
    } catch (e) {}
  }
  init()

  const state = reactive<newStateModel>({
    firstNews: {
      newsId: 0,
      coverUri: '',
      publishTime: 0,
      title: '',
      homeTitle: '',
      summary: '',
      setPublishTime: 0
    },
    restNews: [],
    tab: 'news',
    activeIndex: 0,
    isiOS: false,
    showDesc: true,
    show: false
  })

  const handleMouse = (index: number) => {
    state.activeIndex = index
  }

  const handleClick = (tab: string) => {
    state.tab = tab
    state.activeIndex = 0
    settingDomHeight()
  }

  const handleResolve = () => {
    router.push({
      path: `/news?type=${state.tab}`
    })
  }

  const handleSelect = (id: number, index: number, type: string) => {
    router.push({
      path: `/news?type=${type}&index=${index}&id=${id}`
    })
  }

  const handleSetShow = (status: boolean) => {
    state.show = status
    settingDomHeight()
  }

  watch(
    [() => state.tab, news, media],
    () => {
      if (!news.value.length && !media.value.length) {
        return
      }
      state.firstNews = state.tab == 'news' ? news.value[0] : media.value[0]
      state.restNews =
        state.tab == 'news' ? news.value.slice(1, 6) : media.value.slice(1, 6)
    },
    { immediate: true }
  )

  const settingStyle = () => {
    const u = navigator.userAgent
    const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    state.isiOS = isiOS
  }

  const settingDomHeight = () => {
    const target: any = document.querySelector('.target-title')
    nextTick(() => {
      if (target?.clientHeight) {
        if (target.clientHeight >= 40) {
          target.classList.add('ellipsis-two')
          target.classList.add('line-add')
          state.showDesc = false
        } else {
          target.classList.remove('ellipsis-two')
          target.classList.remove('line-add')
          state.showDesc = true
        }
      }
    })
  }

  const onResize = () => {
    _.throttle(settingDomHeight, 500)()
  }

  onMounted(() => {
    settingStyle()
    window.addEventListener('resize', onResize, false)
  })

  return {
    state,
    parseTime,
    handleSetShow,
    handleMouse,
    handleClick,
    handleResolve,
    handleSelect
  }
}
